import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Rating } from 'react-simple-star-rating'
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

const RebateConfirmation = (props) => {
    const { rebateBusinessListing } = props;
    const navigate = useNavigate();
    let token = localStorage.getItem('token')

    useEffect(() => {
        if (token !== null) {
            props.getRebateData()
        } else {
            navigate('/login')
        }
    }, [])

    let userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [rating, setRating] = useState(0)
    const [ratingComment, setRatingComment] = useState('')
    // const [selectedReviewBusiness, setSelectedBusiness] = useState({
    //     rebateId: ''
    // })

    const changeConfirmationStatus = (data) => {
        console.log(data)
        props.ownerConfirmtation(data)
    }

    // Define table columns
    const columns = [
        {
            name: 'Customer name',
            selector: row => row.customerName,
            sortable: true
        },
        // {
        //     name: 'customer Email',
        //     selector: row => row.customerEmail,
        //     sortable: true
        // },
        {
            name: 'Mobile No',
            selector: row => '+'+row.mobileNo,
            sortable: true
        },
        {
            name: 'proofSubmittedDate',
            selector: row => row.proofSubmittedDate,
            sortable: true
        },
        
        // {
        //     name: 'amount',
        //     selector: row => row.amount,
        //     sortable: true
        // },
        {
            name: 'type',
            selector: row => row.type,
            sortable: true
        },
        // {
        //     name: 'ratedBusiness',
        //     selector: row => row.ratedBusiness,
        //     sortable: true
        // },
        // {
        //     name: 'paymentMethod',
        //     selector: row => row.paymentMethod,
        //     sortable: true
        // },
        // {
        //     name: 'businessConfirmation',
        //     selector: row => row.businessConfirmation,
        //     sortable: true
        // },
        {
            name: 'owner_confirmation',
            cell: row => (
                <button 
                    className="btn btn-primary btn-sm" 
                    onClick={(e) => changeConfirmationStatus(row.rebateId)}
                >
                    {row.owner_confirmation ? "YES": "NO"}
                </button>
            )
        }
    ];
    // console.log(rebateBusinessListing, "rebateBusinessListing")
    return (
        <div className='nearby-business-sec pt-4 pb-5'>
            <Helmet>
                <title>Rebate Confirmation</title>
            </Helmet>

            <div className='container-fluid'>
                <div className='section-header text-center mb-4' style={{ maxWidth: '1000px' }}>
                    <div className='section-label fs-3'>Rebate Confirmation</div>
                </div>

                <div className="row g-3">
                    <div className="col-lg-3 col-xl-2"></div>

                    <div className="col-lg-6 col-xl-8">
                        <div className='row g-3'>
                            {rebateBusinessListing?.length > 0 ? (
                                <DataTable
                                    columns={columns}
                                    data={rebateBusinessListing}
                                    pagination
                                    highlightOnHover
                                    striped
                                />
                            ) : (
                                <div className='fs-3 text-danger text-center'>
                                    There is no business in your area!
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-lg-3 col-xl-2"></div>
                </div>
            </div>

        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        rebateBusinessListing: state.rebate.rebateBusinessListing
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRebateData: () => dispatch(actions.getRebateData()),
        ownerConfirmtation: (data) => dispatch(actions.ownerConfirmtation(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(RebateConfirmation));
