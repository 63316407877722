import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import { useNavigate, useLocation} from "react-router-dom";

const ProofOfPurchase = (props) => {
    const navigate = useNavigate();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const { addProofOfPurchase } = props;
    const location = useLocation(); // Get location data
    const [formData, setFormData] = useState({
        conditionCheck: "",
        paymentMethod: "",
        businessProfileCheck: "",
    });
    const { selectedOptions , rebateData, amount} = location.state; // Extract passed state
    
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const removeFile = (index) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
    console.log(uploadedFiles)
    console.log(selectedOptions)
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent form from refreshing
    
        if (!uploadedFiles || uploadedFiles.length === 0) {
            alert("Please upload at least one file.");
            return;
        }
    
        // Prepare Form Data
        const data = new FormData();
        data.append("ratedBusiness", formData.conditionCheck);
        data.append("paymentMethod", formData.paymentMethod);
        data.append("businessConfirmation", formData.businessProfileCheck);
        data.append("userId", userInfo._id);
        // Append Uploaded Files
        uploadedFiles.forEach((file) => {
            data.append("uploadedFiles", file);
        });
        // selectedOptions.forEach((e) => {
            data.append("selectedOptions", JSON.stringify(selectedOptions));
        // });        
        data.append("rebateData", JSON.stringify(rebateData));
        data.append("rebateId", rebateData._id);
        data.append("amount", amount);

        // Dispatch Redux Action
        addProofOfPurchase(data, navigate);
        // navigate('/')
    };
    
    // Prevent accidental page reloads while form data is being entered
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ""; // Required for modern browsers
        };
    
        window.addEventListener("beforeunload", handleBeforeUnload);
    
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);    
    return (
        <div className="ProofOfPurchase-page pt-3 pb-5">
            <div className="container">
                <Helmet>
                    <title>Proof of Purchase</title>
                </Helmet>

                <div className="section-label fs-2 lh-sm mb-4">Proof of Purchase</div>

                <div className="info-wrapper">
                    <Form onSubmit={handleSubmit}>
                        <div className="text-primary fs-6 fw-medium mb-2">Upload clear copies and receipts used for this service</div>
                        <Form.Group className="mb-3">
                            <Form.Label className="fs-6">Did you rate & comment that business service on couponranking platform -</Form.Label>
                            <div className="d-flex gap-3">
                                <Form.Check 
                                    type="radio" 
                                    id="conditionCheckYes" 
                                    name="conditionCheck" 
                                    label="Yes" 
                                    onChange={handleInputChange}
                                    value="Yes"
                                />
                                <Form.Check 
                                    type="radio" 
                                    id="conditionCheckNo" 
                                    name="conditionCheck" 
                                    label="No"
                                    value="Yes"
                                    onChange={handleInputChange} 
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fs-6">What payment method do you want couponranking me to use to pay you -</Form.Label>
                            <div className="d-flex gap-3">
                                <Form.Check 
                                    type="radio" 
                                    id="paymentMethod1" 
                                    name="paymentMethod" 
                                    label="zelle" 
                                    value="zelle"
                                    onChange={handleInputChange}
                                />
                                <Form.Check 
                                    type="radio" 
                                    id="paymentMethod2" 
                                    name="paymentMethod" 
                                    label="Cash App" 
                                    value="Cash App"
                                    onChange={handleInputChange}
                                />
                                <Form.Check 
                                    type="radio" 
                                    id="paymentMethod3" 
                                    name="paymentMethod" 
                                    value="Not Sure"
                                    label="Not Sure"
                                    onChange={handleInputChange}
                                />
                                <Form.Check 
                                    type="radio" 
                                    id="paymentMethod4" 
                                    name="paymentMethod" 
                                    label="Gift Card" 
                                    value="Gift Card"
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fs-6">Did the business click (mark) "Yes" on couponranking business profile as a proof that you have completed the service (Remind the bisiness owner or manager to login into couponranking business profile and click "Yes" on the business profile) -</Form.Label>
                            <div className="d-flex gap-3">
                                <Form.Check 
                                    type="radio" 
                                    id="businessProfileYes" 
                                    name="businessProfileCheck" 
                                    label="Yes"
                                    value="Yes" 
                                    onChange={handleInputChange} 
                                />
                                <Form.Check 
                                    type="radio" 
                                    id="businessProfileNo" 
                                    name="businessProfileCheck" 
                                    label="No" 
                                    value="No" 
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="uploadProofFile" className="mb-3">
                            <Form.Label className="text-danger">Upload Proofs -</Form.Label>
                            <Form.Control type="file" multiple className="h-auto" onChange={handleFileUpload}/>
                        </Form.Group>

                        <div className="info-details mb-4">
                            <div className="fs-5 fw-bold mb-2">Documents proof list -</div>
                            <div className="proof-wrapper border border-secondary border-opacity-50 rounded-3 p-3">
                            <div className="proof-wrapper border border-secondary border-opacity-50 rounded-3 p-3">
                                {uploadedFiles.length > 0 ? (
                                    <div className="proof-list d-flex flex-wrap gap-2">
                                        {uploadedFiles.map((file, index) => (
                                            <div
                                                className="proof-item bg-secondary bg-opacity-10 border position-relative rounded-1 p-2"
                                                key={index}
                                            >
                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt="Document Proof"
                                                    className="img-fluid rounded-1"
                                                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                                />
                                                <span
                                                    className="cross-btn bg-danger text-white d-flex align-items-center justify-content-center rounded-circle position-absolute top-0 end-0 z-3 pointer m-n2"
                                                    style={{ height: "18px", width: "18px", cursor: "pointer" }}
                                                    onClick={() => removeFile(index)}
                                                >
                                                    <i className="bi bi-x lh-1"></i>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="no-data-message">No data available</div>
                                )}
                            </div>

                            </div>
                        </div>

                        <div className="important-points">
                            <div className="section-label fs-3 text-start lh-sm mb-3">Important Notes:</div>
                            <ul className="list ps-3">
                                <li>Limited offers are available each month for each city</li>
                                <li>Ensure to follow all steps carefully to qualify for this rebate.</li>
                                <li>For more details or assistance, feel free to contact us at <Link to="mailto:sales@couponranking.com" className="text-decoration-none">sales@couponranking.com</Link>.</li>
                            </ul>
                        </div>

                        <div className="btn-wrapper text-center mt-4 pt-lg-3">
                            <Button type="submit" variant="primary">
                                Submit <i className="bi bi-arrow-right ms-1"></i>
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.rebate.loading,
        error: state.rebate.error,
        success: state.rebate.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addProofOfPurchase: (data, navigate) => dispatch(actions.addProofOfPurchase(data, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(ProofOfPurchase))