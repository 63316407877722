import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startrebateAction = () => {
  return {
    type: actionTypes.START_REBATE_ACTION
  };
};

export const rebateActionFail = message => {
  return {
    type: actionTypes.REBATE_ACTION_FAIL,
    message
  };
};

export const addedProofPurchase = message => {
  return {
    type: actionTypes.ADDED_PROOF_PURCHASE,
    message
  };
};

export const fetchrebateListSUCCESS = (rebateList, cities, message = '') => {
  return {
    type: actionTypes.FETCH_REBATE_LIST_SUCCESS,
    listing: rebateList,
    cities: cities,
    message: message ? message : ''
  };
};
export const fetchEngagedListSUCCESS = (listing, message = '') => {
  return {
    type: actionTypes.FETCH_ENGAGED_BUSINESS_LIST_SUCCESS,
    businessData: listing,
    message: message ? message : ''
  };
};
export const fetchBusinessRebateListSUCCESS = (listing, message = '') => {
  return {
    type: actionTypes.FETCH_BUSINESS_REBATE_LIST_SUCCESS,
    businessData: listing,
    message: message ? message : ''
  };
};
export const getRebateList = (data) => {
  return dispatch => {
    dispatch(startrebateAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-rebate", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchrebateListSUCCESS(response.data.data, response.data.cities));
          } else {
            dispatch(rebateActionFail(response.data.message));
          }
        } else {
          dispatch(rebateActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(rebateActionFail(err.message));
      });
  };
};
export const getEngagedBusiness = () => {
  return dispatch => {
    dispatch(startrebateAction());
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/list-user-engaged-business", userInfo, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchEngagedListSUCCESS(response.data.data));
          } else {
            dispatch(rebateActionFail(response.data.message));
          }
        } else {
          dispatch(rebateActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(rebateActionFail(err.message));
      });
  };
};

export const addProofOfPurchase = (data, navigate) => {
  return dispatch => {
    dispatch(startrebateAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/add-proof-of-purchase", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(addedProofPurchase(response.data.message));
            navigate('/');
          } else {
            dispatch(rebateActionFail(response.data.message));
          }
        } else {
          dispatch(rebateActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(rebateActionFail(err.message));
      });
  };
}
export const getRebateData = () => {
  return dispatch => {
    dispatch(startrebateAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    
    axios
      .get("/couponranking/get-rebate-data/"+userInfo._id, {
        // .get("/couponranking/get-rebate-data/64e8f57a85d4a7c50f05791d", {

        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchBusinessRebateListSUCCESS(response.data.data));
          } else {
            dispatch(rebateActionFail(response.data.message));
          }
        } else {
          dispatch(rebateActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(rebateActionFail(err.message));
      });
  };
};
export const ownerConfirmtation = (rebateId) => {
  return dispatch => {
    dispatch(startrebateAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    
    axios
      .put("/couponranking/owner-confirmtation/"+rebateId, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getRebateData());
            
          } else {
            dispatch(rebateActionFail(response.data.message));
          }
        } else {
          dispatch(rebateActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(rebateActionFail(err.message));
      });
  };
};
