import React from 'react';
import catering from '../assets/icons/catering.svg';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useEffect } from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddReferenceDetails = (props) => {
    let { referenceList } = props
    console.log(referenceList)
    const [selectedRole, setSelectedRole] = useState('');
    const [formData, setFormData] = useState({
        // referal_name: "",
        // referenceEmail: "",
        // referencePhone: "",
        // relationship: "",
        // company: "",
        // position: "",
        referal_name: "",
        referal_phone: "",
        referal_email: "",
        referal_payment_method: "",
        other_referal_payment_method: "",
        _id: ""
    });
    useEffect(() => {
        props.getReferenceData()
        
    }, []);
    const handleChange = (e) => {
        console.log(e.target.name, e.target.value)
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRoleChange = (e) => {
        setSelectedRole(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await props.addReferenceDetailsApi({
            referal_from: selectedRole, 
            referal_name: formData.referal_name , 
            referal_email: formData.referal_email , 
            referal_phone: formData.referal_phone , 
            referal_payment_method: formData.referal_payment_method,
            other_referal_payment_method: formData.other_referal_payment_method,
            sales_referals: formData._id
        })
        return
        console.log("Form submitted:", formData);
    };

    return (
        <div className='business-details-page py-4 py-xl-5'>
            {/* title head */}
            <Helmet>
                <title>CouponRanking | Add Reference Details</title>
            </Helmet>
            <ToastContainer />
            <div className='container'>
                <div className='section-label fs-1 mb-4'>Add Reference Details</div>
                <form className='bg-light px-3 px-md-5 py-4 py-md-5' onSubmit={handleSubmit}>
                    
                    {/* Website and click section */}
                    <div className="row gx-3">
                        <div className="col-sm-6">
                            {/* <div className="mb-3">
                                <label className="form-label" htmlFor="bWebsite">Business Website</label>
                                <input type="url" id="bWebsite" className="form-control" placeholder="Enter website" required
                                    value={website}
                                    onChange={(event) => setWebsite(event.target.value)}
                                />
                                <div className='invalid-feedback'></div>
                            </div> */}
                        <div className="space-y-2">
                            <label><input type="radio" name="role" value="salesConsultant" onChange={handleRoleChange} /> Sales Consultant</label>
                        </div>

                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                            <label>
                                <input type="radio" name="role" value="salesManager" onChange={handleRoleChange} /> 
                                Sales Manager
                            </label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                            <label><input type="radio" name="role" value="referral" onChange={handleRoleChange} /> Referral</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                            <label><input type="radio" name="role" value="yourself" onChange={handleRoleChange} /> Yourself</label>
                            </div>
                        </div>
                    </div>

                    {/* Category and subcategory click section */}
                    <div className="row gx-3">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                
                    {selectedRole === "salesConsultant" && (
                        // <input type="text" name="referal_name" placeholder="Sales Consultant Full Name" value={formData.referal_name} onChange={handleChange} 
                        // className="form-control" 
                        // required />
                        <select
                            name="_id"
                            value={formData._id}
                            onChange={handleChange}
                            className="form-control"
                            required
                            >
                            <option value="">Select Sales Consultant</option>
                            {referenceList
                                .filter((ref) => ref.type === "salesConsultant") // Only show sales consultants
                                .map((ref) => (
                                <option key={ref._id} value={ref._id}>
                                    {ref.first_name} {ref.last_name}
                                </option>
                                ))}
                            </select>

                    )}

                    {selectedRole === "salesManager" && (
                        // <input type="text" name="referal_name" placeholder="Sales Manager Full Name" value={formData.referal_name} onChange={handleChange} className="form-control" required />
                        <select
                        name="_id"
                        value={formData._id}
                        onChange={handleChange}
                        className="form-control"
                        required
                        >
                        <option value="">Select Sales Consultant</option>
                        {referenceList
                            .filter((ref) => ref.type === "salesManager") // Only show sales consultants
                            .map((ref) => (
                            <option key={ref._id} value={ref._id}>
                                {ref.first_name} {ref.last_name}
                            </option>
                            ))}
                        </select>

                    )}

                    {selectedRole === "referral" && (
                        <>
                            <input type="text" name="referal_name" placeholder="Referral Full Name" value={formData.referal_name} onChange={handleChange} className="form-control" required />
                            <input type="tel" name="referal_phone" placeholder="Referral Phone Number" value={formData.referal_phone} onChange={handleChange} className="form-control" required />
                            <input type="email" name="referal_email" placeholder="Referral Email" value={formData.referal_email} onChange={handleChange} className="form-control" required />
                            <div className="col-sm-12">
                                <div className="mb-3">
                                    <label>How do you want to be paid?</label>
                                    <label><input type="radio" name="referal_payment_method" value="zelle" onChange={handleChange} /> Zelle</label>
                                    <label><input type="radio" name="referal_payment_method" value="cashApp" onChange={handleChange} /> Cash App</label>
                                    <label><input type="radio" name="referal_payment_method" value="giftCard" onChange={handleChange} /> Gift Card</label>
                                    <label><input type="radio" name="referal_payment_method" value="other" onChange={handleChange} /> Other</label>
                                    {formData.paymentMethod === "other" && (
                                        <input type="text" name="other_referal_payment_method" placeholder="Specify Other Payment Method" value={formData.other_referal_payment_method} onChange={handleChange} className="form-control" />
                                    )}
                                </div>
                            </div>
                            
                            <p className="text-sm text-gray-500">Referrals are paid every Friday</p>
                        </>
                    )}

                    {/* <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">Submit</button> */}

                            </div>
                        </div>
                    </div>

                    <div className='btn-wrapper mt-4'>
                        <button type='submit' className='btn btn-primary px-5' 
                        // onSubmit={handleSubmit}
                        >Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        categoryList: state.business.categoryList,
        referenceList: state.business.referenceList,
        planDetails: state.subscriptionPlan.planDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCategoryList: () => dispatch(actions.getCategoryList()),
        getPlanDetailOfUser: () => dispatch(actions.getPlanDetailOfUser()),
        getReferenceData: () => dispatch(actions.getReferenceData()),
        addReferenceDetailsApi: (data) => dispatch(actions.addReferenceDetailsApi(data))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(AddReferenceDetails))
