import React from 'react';
import { Helmet } from "react-helmet";

const ReferralProgram = () => {
    return (
        <div className="container mt-4">
            <Helmet>
                <title>CouponRanking | Referral Program</title>
            </Helmet>
            <h1 className="text-center mb-4">Referral Program</h1>
            
            <div className="referral-section">
                <h3>Earn $200 and more for every Business you refer to CouponRanking!</h3>
                <p>Looking for an easy way to make extra cash? Refer a business to CouponRanking, and when they sign up, you’ll receive $200 or more!</p>
            </div>

            <div className="referral-section">
                <h3>How to Refer & Earn:</h3>
                <ul>
                    <li>Visit CouponRanking platform</li>
                    <li>Click on "Login" and select "Business Owner Sign Up"</li>
                    <li>Register the Business by following the simple steps to complete the process</li>
                    <li>Enter Your Referral information (Name, Email, and Phone Number)</li>
                    <li>Receive Your Payment - Your earnings will be sent via Zelle or Cash App</li>
                </ul>
            </div>

            <div className="referral-section">
                <h3>Referral Guidelines:</h3>
                <ul>
                    <li>The referred business must sign up for a yearly membership</li>
                    <li>Choose the “Regular Plan” with a 24-month contract</li>
                    <li>Select the "Free Marketplace Trial Plan" (1-month free trial)</li>
                </ul>
            </div>

            <div className="referral-section">
                <p>For any questions or inquiries, contact <a href="mailto:sales@couponranking.com">sales@couponranking.com</a></p>
                <p>Start referring today and earn money with ease!</p>
                <p>Watch the YouTube video link below to help you successfully sign up a business:</p>
            </div>
        </div>
    );
};

export default ReferralProgram;
