import React, { useState } from "react";
import "./index.scss";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import { useEffect } from 'react';

const ClaimYourRebate = (props) => {
    const navigate = useNavigate();
    const { getRebateList, rebateListing, cityListing } = props;
    const now = new Date();
    const [data, setData] = useState({})
    const [selectedCity, setSelectedCity] = useState(""); 
    const month = now.toLocaleString('en-US', { month: 'long' }); // e.g., "February"
    const year = now.getFullYear(); // e.g., 2025
    
    const monthNumber = now.getMonth() + 1; // Adding 1 since getMonth() returns 0-based index
    const yearNumber = now.getFullYear();
    let token = localStorage.getItem('token')
    // console.log(monthNumber, yearNumber)
    useEffect(() => {
        console.log(token)
        if (token !== null) {
            getRebateList({
                monthNumber: monthNumber,
                yearNumber: yearNumber
            }) 
        } else {
            navigate('/login')
        }
    }, [getRebateList, monthNumber, yearNumber])
    console.log(rebateListing, cityListing)
    const handleChange = async (e) => {
        await setSelectedCity(e);
        await rebateListing.map(ele => {
            if (ele.city === e) {
                setData(ele);
            }
        });
    }
    console.log(data, "data")
    return (
        <div className="ClaimYourRebate-page pt-3 pb-5">
            <div className="container">
                <Helmet>
                    <title>Claim Your Rebate</title>
                </Helmet>
                <div className="section-label fs-2 mb-3">Claim Your Rebate</div>

                <div className="top-info mb-3">
                    <div className="month_info fs-18 mb-3">
                        <span className="fw-semibold">PLEASE NOTE:</span>
                        Rebate are not claimed on a fixed monthly schedule 
                        but rather based on the number of eligible claims you have.
                        You are allowed to submit multiple claims in the same month.
                    </div>
                    <div className="month_info fs-18 mb-3">
                        <span className="fw-semibold">IMPORTANT NOTES:</span>
                        Limited offers are available each month of each city.
                        Ensure to follow all steps carefully to qualify for this 
                        rebate. For more details or assistance, feel free to contact us at <a href="mailto:sales@couponranking.com">sales@couponranking.com</a>
                    </div>
                    <div className="month_info fs-18 mb-3">
                        <span className="fw-semibold">Valid Month:</span> {month}, {year}
                    </div>
                    <Form.Select 
                        aria-label="Default select example"
                        // value={selectedCity}
                        onChange={(e) => handleChange(e.target.value)}
                    >
                    <option value="">Select your city/state</option>
                    {cityListing.map((city, index) => (
                        <option key={index} value={city}>{city}</option>
                    ))}
                    </Form.Select>
                </div>

                <div className="row g-3 gx-xl-4 mb-3">
                    <div className="col-sm-6">
                        <div className="info-box h-100 border rounded-3 p-3">
                            <div className="title fs-18 mb-1">Total number of rebate claimed</div>
                            <div className="value fs-3 fw-bold lh-sm">{data?.rebate_claimed}</div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="info-box h-100 border rounded-3 p-3">
                            <div className="title fs-18 mb-1">Total number of unclaimed rebate remaining</div>
                            <div className="value fs-3 fw-bold lh-sm">{data?.rebate_unclaimed_remaining}</div>
                        </div>
                    </div>
                </div>
                {selectedCity === "" ? <></>: 
                    <div className="amount-checklist-wrapper">
                        <div className="label-heading text-danger fs-18 fw-semibold mb-2">Choose the rebate amount you qualified for and verify it's availablity below-</div>
                        <Form>
                            <div className="row g-3 gx-xl-4">
                                <div className="col-md-6">
                                    <Form.Check 
                                        type="radio" 
                                        name="selectRebateAmount" 
                                        className="checks_item p-0" 
                                        id="customCheck1" 
                                        required
                                        onClick={() => 
                                            {
                                                if (data?.remaining_claim_1000 !== 0) {
                                                    navigate("/rebate-business-list", { 
                                                      state: { 
                                                        rebateData: data, 
                                                        claim: data?.claim_1000, 
                                                        remaining_claim: data?.remaining_claim_1000,
                                                        reciept: data?.reciept_1000,
                                                        amount: '1000.00',
                                                      } 
                                                    });
                                                  }
                                                }}
                                        label={
                                            <div className="info-wrapper bg-secondary bg-opacity-25 rounded-3 pointer p-3">
                                                <div className="text-black fw-bold mb-2">$1000.00 Rebate</div>
                                                <div className="row g-2 g-lg-3 mb-2">
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of rebate claimed</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.claim_1000}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of unclaimed rebate remaining</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.remaining_claim_1000}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="warnign-info text-success fw-medium">Provide {data?.reciept_1000} business receipts to qualify for $1000.00 rebate.</div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form.Check 
                                        type="radio" 
                                        name="selectRebateAmount" 
                                        className="checks_item p-0" 
                                        id="customCheck2"
                                        onClick={() => 
                                        {
                                            if (data?.remaining_claim_500 !== 0) {
                                                navigate("/rebate-business-list", { state: { rebateData: data,
                                                    claim: data?.claim_500, 
                                                    remaining_claim: data?.remaining_claim_500,
                                                    reciept: data?.reciept_500,
                                                    amount: '500.00',
                                                } })
                                            }
                                        }}
                                        label={
                                            <div className="info-wrapper bg-secondary bg-opacity-25 rounded-3 pointer p-3">
                                                <div className="text-black fw-bold mb-2">$500.00 Rebate</div>
                                                <div className="row g-2 g-lg-3 mb-2">
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of rebate claimed</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.claim_500}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of unclaimed rebate remaining</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.remaining_claim_500}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="warnign-info text-success fw-medium">Provide {data?.reciept_500} business receipts to qualify for $500.00 rebate.</div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form.Check 
                                        type="radio" 
                                        name="selectRebateAmount" 
                                        className="checks_item p-0" 
                                        id="customCheck3"
                                        onClick={() => 
                                            {
                                                if (data?.remaining_claim_200 !== 0) {
                                            navigate("/rebate-business-list", { state: { rebateData: data,
                                                                                                claim: data?.claim_200, 
                                                                                                remaining_claim: data?.remaining_claim_200,
                                                                                                reciept: data?.reciept_200,
                                                                                                amount: '200.00',
                                         } })}}}
                                        label={
                                            <div className="info-wrapper bg-secondary bg-opacity-25 rounded-3 pointer p-3">
                                                <div className="text-black fw-bold mb-2">$200.00 Rebate</div>
                                                <div className="row g-2 g-lg-3 mb-2">
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of rebate claimed</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.claim_200}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of unclaimed rebate remaining</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.remaining_claim_200}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="warnign-info text-success fw-medium">Provide {data?.reciept_200} business receipts to qualify for $200.00 rebate.</div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form.Check 
                                        type="radio" 
                                        name="selectRebateAmount" 
                                        className="checks_item p-0" 
                                        id="customCheck4"
                                        onClick={() => 
                                        {
                                            if (data?.remaining_claim_100 !== 0) {
                                                navigate("/rebate-business-list", { state: { rebateData: data,claim: data?.claim_100, 
                                                    remaining_claim: data?.remaining_claim_100,
                                                    reciept: data?.reciept_100,amount: '100.00',
                                                  } })
                                            }
                                        }
                                            }
                                        label={
                                            <div className="info-wrapper bg-secondary bg-opacity-25 rounded-3 pointer p-3">
                                                <div className="text-black fw-bold mb-2">$100.00 Rebate</div>
                                                <div className="row g-2 g-lg-3 mb-2">
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of rebate claimed</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.claim_100}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of unclaimed rebate remaining</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.remaining_claim_100}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="warnign-info text-success fw-medium">Provide {data?.reciept_100} business receipts to qualify for $100.00rebate.</div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form.Check 
                                        type="radio" 
                                        name="selectRebateAmount" 
                                        className="checks_item p-0" 
                                        id="customCheck5"
                                        onClick={() => 
                                        {
                                            if (data?.remaining_claim_50 !== 0) {
                                                navigate("/rebate-business-list", { state: { rebateData: data,claim: data?.claim_50, 
                                                    remaining_claim: data?.remaining_claim_50,
                                                    reciept: data?.reciept_50, amount: '50.00',  } })
                                            }
                                        }}
                                        label={
                                            <div className="info-wrapper bg-secondary bg-opacity-25 rounded-3 pointer p-3">
                                                <div className="text-black fw-bold mb-2">$50.00 Rebate</div>
                                                <div className="row g-2 g-lg-3 mb-2">
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of rebate claimed</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.claim_50}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of unclaimed rebate remaining</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.remaining_claim_50}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="warnign-info text-success fw-medium">Provide {data?.reciept_50} business receipts to qualify for $50.00 rebate.</div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form.Check 
                                        type="radio" 
                                        name="selectRebateAmount" 
                                        className="checks_item p-0" 
                                        id="customCheck6"
                                        onClick={() => 
                                        {
                                            if (data?.remaining_claim_20 !== 0) {
                                                navigate("/rebate-business-list", { state: { rebateData: data,claim: data?.claim_20, 
                                                    remaining_claim: data?.remaining_claim_20,
                                                    reciept: data?.reciept_20, amount: '20.00', } })
                                            }
                                        }}
                                        label={
                                            <div className="info-wrapper bg-secondary bg-opacity-25 rounded-3 pointer p-3">
                                                <div className="text-black fw-bold mb-2">$20.00 Rebate</div>
                                                <div className="row g-2 g-lg-3 mb-2">
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of rebate claimed</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.claim_20}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of unclaimed rebate remaining</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.remaining_claim_20}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="warnign-info text-success fw-medium">Provide {data?.reciept_20} business receipts to qualify for $20.00 rebate.</div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form.Check 
                                        type="radio" 
                                        name="selectRebateAmount" 
                                        className="checks_item p-0" 
                                        id="customCheck7"
                                        onClick={() => 
                                        {
                                            if (data?.remaining_claim_5 !== 0) {
                                                navigate("/rebate-business-list", { state: { rebateData: data,claim: data?.claim_5, 
                                                    remaining_claim: data?.remaining_claim_5,
                                                    reciept: data?.reciept_5,
                                                    amount: '5.00',
                                                 } })
                                            }
                                        }}
                                        label={
                                            <div className="info-wrapper bg-secondary bg-opacity-25 rounded-3 pointer p-3">
                                                <div className="text-black fw-bold mb-2">$5.00 Rebate</div>
                                                <div className="row g-2 g-lg-3 mb-2">
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of rebate claimed</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.claim_5}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="info-box h-100 border border-secondary border-opacity-50 rounded-3 p-2">
                                                            <div className="title mb-1">Total number of unclaimed rebate remaining</div>
                                                            <div className="value fs-5 fw-bold lh-sm">{data?.remaining_claim_5}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="warnign-info text-success fw-medium">Provide {data?.reciept_5} business receipts to qualify for $5.00 rebate.</div>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                            {/* <div className="btn-wrapper text-center mt-4 pt-lg-3">
                                <Button type="submit" variant="primary" onClick={()=> navigate("/rebate-business-list")}>
                                    Next Page <i className="bi bi-arrow-right ms-1"></i>
                                </Button>
                            </div> */}
                        </Form>
                    </div>
                }
            </div>
        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.rebate.loading,
        error: state.rebate.error,
        success: state.rebate.success,
        rebateListing: state.rebate.rebateListing,
        cityListing: state.rebate.cityListing,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRebateList: (data) => dispatch(actions.getRebateList(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(ClaimYourRebate))