import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startAuth = () => {
  return {
    type: actionTypes.START_AUTH
  };
}

export const AuthSuccess = (message) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    message: message,
  };
}

export const AuthFail = (message) => {
  return {
    type: actionTypes.AUTH_FAIL,
    message: message
  };
}

export const login = (data, navigate) => {
  return dispatch => {
    dispatch(startAuth)
    axios.post("couponranking/login", data)
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 0) {
            dispatch(AuthFail(response.data.message))
          } else if (response.data.status === 200) {
            console.log("response.data.data", response.data.data.planType);
            // return
            dispatch(AuthSuccess(response.data.message))
            if (response.data.data.user_type === 'user') {
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem("userInfo", JSON.stringify(response.data.data));
              window.location = '/'
            }  else if (
              response.data.data.user_type === 'business_user' && 
              response.data.data.cardInfo.length > 0 && 
              response.data.data.planType === undefined
            ) {
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem("userInfo", JSON.stringify(response.data.data));
              navigate('/select-ad-type', { state: { token: response.data.data.token }, replace: true })
            } else if (response.data.data.user_type === 'business_user' && response.data.data.cardInfo.length > 0) {
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem("userInfo", JSON.stringify(response.data.data));
              window.location = '/'
            } else if (response.data.data.user_type === 'business_user' && response.data.data.cardInfo.length === 0) {
              // navigate('/save-card', { state: { token: response.data.data.token }, replace: true })
            // } else {
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem("userInfo", JSON.stringify(response.data.data));
              
              navigate('/welcome-page', { state: { token: response.data.data.token }, replace: true })
            } 
          } else {
            dispatch(AuthFail(response.data.message))
          }
        } else {
          dispatch(AuthFail(response.data.message))
        }
      })
      .catch((err) => {
        dispatch(AuthFail(err.response.data.message))
      })
  }
}

export const register = (data) => {
  return dispatch => {
    dispatch(startAuth());
    axios
      .post(`/couponranking/register`, data)
      .then(response => {
        console.log(response, 'hello response')
        if (response.status === 200) {
          if (response.data.status === 200) {
            localStorage.setItem("registerUserEmail", data.email);
            dispatch(AuthSuccess(response.data.message))
            const load = () => { window.location.replace('/verify-user') }
            setTimeout(load, 2000);
          } else if (response.data.status === 1) {
            localStorage.setItem("registerUserEmail", data.email);
            dispatch(AuthFail(response.data.message))
          } else {
            dispatch(AuthFail(response.data.message))
          }
        } else {
          dispatch(AuthFail(response.message))
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(AuthFail(err.message))
      });
  };
};


export const verifyOtp = (data, navigate) => {
  return async dispatch => {
    axios
      .post("couponranking/verifyUser", data, {
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            if (response.data.data.user_type === 'user') {

              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem("userInfo", JSON.stringify(response.data.data));
              const load = () => { navigate('/') }
              setTimeout(load, 2000);
            }
            else if (response.data.data.user_type === 'business_user') {
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem("userInfo", JSON.stringify(response.data.data));

              navigate('/welcome-page', { state: { token: response.data.data.token }, replace: true })
            } 
            else {
              const load = () => { navigate('/save-card', { state: { token: response.data.data.token }, replace: true }) }
              setTimeout(load, 2000);
            }
          } else if (response.data.status === 0) {
            dispatch(AuthFail(response.data.message))
          }
        }
      })
      .catch(err => {
        dispatch(AuthFail(err.message))
        console.log(err)
      });
  };
}



export const resendOtp = (data) => {
  return dispatch => {
    axios
      .post("/couponranking/resend-otp", data, {
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(AuthSuccess(response.data.message))
          } else if (response.data.status === 0) {
            dispatch(AuthFail(response.data.message))
          }
        }
      })
      .catch(err => {
        dispatch(AuthFail(err.message))
        console.log(err)
      });
  };
}

export const saveCard = (details, navigate) => {
  let data = {
    tokenId: details.tokenId
  }
  let token = details.authtoken
  return dispatch => {
    const authCode = "Bearer " + token;
    axios
      .post("/couponranking/save-card", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(AuthSuccess(response.data.message))
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("userInfo", JSON.stringify(response.data.data));
            // const load = () => { navigate('/pricing-plan', { state: { token: token }, replace: true }) }
            const load = () => { navigate('/select-ad-type', { state: { token: token }, replace: true }) }
            setTimeout(load, 2000);
          } else if (response.data.status === 0) {
            dispatch(AuthFail(response.data.message))
          }
        }
      })
      .catch(err => {
        dispatch(AuthFail(err.message))
        console.log(err)
      });
  };
}

export const getCardDetailSuccess = (details, message) => {
  return {
    type: actionTypes.GET_CARD_DETAILS_SUCCESS,
    message: message,
    cardDetails: details
  };
}

export const getCitySuccess = (details, message) => {
  return {
    type: actionTypes.GET_CITY_SUCCESS,
    message: message,
    cities: details
  };
}
export const getCities = () => {
  return dispatch => {
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("/couponranking/get-cities")
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getCitySuccess(response.data.data, null))
          } else if (response.data.status === 0) {
            dispatch(AuthFail(response.data.message))
          }
        }
      })
      .catch(err => {
        dispatch(AuthFail(err.message))
        console.log(err)
      });
  };
}
export const getCardDetails = () => {
  return dispatch => {
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("/couponranking/get-card-details", {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getCardDetailSuccess(response.data.data, null))
          } else if (response.data.status === 0) {
            dispatch(AuthFail(response.data.message))
          }
        }
      })
      .catch(err => {
        dispatch(AuthFail(err.message))
        console.log(err)
      });
  };
}

export const updateCardDetails = (data) => {
  return dispatch => {
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/update-card-details", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getCardDetailSuccess(response.data.data, response.data.message))
          } else if (response.data.status === 0) {
            dispatch(AuthFail(response.data.message))
          }
        }
      })
      .catch(err => {
        dispatch(AuthFail(err.message))
        console.log(err)
      });
  };
}

export const ForgotPassword = (data, setSection, toast) => {
  return dispatch => {
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/forgot-password", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(AuthSuccess(response.data.message))
            setSection({
              emailSection: false,
              verifyOtpSection: true,
              resetPassword: false
            })
            toast.success(response.data.message, {
              autoClose: 1000,
              hideProgressBar: true,
            })
          } else if (response.data.status === 0) {
            dispatch(AuthFail(response.data.message))
            toast.error(response.data.message, {
              autoClose: 1000,
              hideProgressBar: true,
            })
          }
        }
      })
      .catch(err => {
        dispatch(AuthFail(err.message))
        console.log(err)
        toast.error(err, {
          autoClose: 1000,
          hideProgressBar: true,
        })
      });
  };
}

export const verifyForgetPasswordOtp = (data, setSection, toast) => {
  return dispatch => {
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/otp-verify", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(AuthSuccess(response.data.message))
            setSection({
              emailSection: false,
              verifyOtpSection: false,
              resetPassword: true
            })
            toast.success(response.data.message, {
              autoClose: 1000,
              hideProgressBar: true,
            })
          } else if (response.data.status === 0) {
            dispatch(AuthFail(response.data.message))
            toast.error(response.data.message, {
              autoClose: 1000,
              hideProgressBar: true,
            })
          }
        } else {
          dispatch(AuthFail(response.message))
          toast.error(response.message, {
            autoClose: 1000,
            hideProgressBar: true,
          })
        }
      })
      .catch(err => {
        dispatch(AuthFail(err.message))
        console.log(err)
        toast.error(err, {
          autoClose: 500,
          hideProgressBar: true,
        })
      });
  };
}

export const resetPassword = (data, toast) => {
  return dispatch => {
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/reset-password", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(AuthSuccess(response.data.message))
            toast.success(response.data.message, {
              autoClose: 1000,
              hideProgressBar: true,
            })
            const load = () => { window.location.replace('/') }
            setTimeout(load, 2000);

          } else if (response.data.status === 0) {
            dispatch(AuthFail(response.data.message))
            toast.error(response.data.message, {
              autoClose: 1000,
              hideProgressBar: true,
            })
          }
        } else {
          dispatch(AuthFail(response.message))
          toast.error(response.message, {
            autoClose: 1000,
            hideProgressBar: true,
          })
        }
      })
      .catch(err => {
        dispatch(AuthFail(err.message))
        console.log(err)
        toast.error(err, {
          autoClose: 500,
          hideProgressBar: true,
        })
      });
  };
}

export const resendForgotPasswordOtp = (data, toast) => {
  return dispatch => {
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/resend-otp", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(AuthSuccess(response.data.message))
            toast.success(response.data.message, {
              autoClose: 1000,
              hideProgressBar: true,
            })
          } else if (response.data.status === 0) {
            dispatch(AuthFail(response.data.message))
            toast.error(response.data.message, {
              autoClose: 1000,
              hideProgressBar: true,
            })
          }
        } else {
          dispatch(AuthFail(response.message))
          toast.error(response.message, {
            autoClose: 1000,
            hideProgressBar: true,
          })
        }
      })
      .catch(err => {
        dispatch(AuthFail(err.message))
        console.log(err)
        toast.error(err, {
          autoClose: 500,
          hideProgressBar: true,
        })
      });
  };
}
