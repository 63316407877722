import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  rebateListing: [],
  cityListing: [],
  rebateBusinessListing: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_REBATE_ACTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.REBATE_ACTION_FAIL:
      return {
        ...state,
        loading: null,
        error: action.message,
        success: null,
    }
    case actionTypes.FETCH_REBATE_LIST_SUCCESS:
      return {
        ...state,
        loading: null,
        rebateListing: action.listing,
        cityListing: action.cities,
        error: null,
        success: action.message,
      }
    case actionTypes.FETCH_ENGAGED_BUSINESS_LIST_SUCCESS: 
      return {
        ...state,
        loading: null,
        engagedBusinessList: action.businessData,
        error: null,
        success: action.message,
      }
    case actionTypes.ADDED_PROOF_PURCHASE:
      return {
        ...state,
        loading: null,
        error: null,
        success: "Added your Claim sucessfully!!",
        
    }
    case actionTypes.FETCH_BUSINESS_REBATE_LIST_SUCCESS: 
      return {
        ...state,
        loading: null,
        rebateBusinessListing: action.businessData,
        error: null,
        success: action.message,
      }
    default:
      return state;
  }
};

export default reducer;
