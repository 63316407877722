import React, { useEffect, useState  } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Elements,
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement
} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
let stripePromise = loadStripe("pk_live_HCDXsOAEmsRJMJkM1ZMnTi6H");
// let stripePromise = loadStripe("pk_test_51JbSM0B6RkVpoAxRZxTe1kM7O5IJ7YlB9eTuiwR4HK0AXBFAv6rYLDatBl9koY8Y6H5vIc10vibiQg88N06p3Jm700bTZfJo4w");

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    let authtoken = props.props.token;
    let navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (elements == null) {
            return;
        }
        setLoading(true);

        let card = await elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
        let { token, error } = await stripe.createToken(card);
        if (error) {
            toast.error(error.message, { autoClose: 1000 })
        } else {
            let data = {
                tokenId: token.id,
                authtoken: authtoken
            }
            await props.props.saveCard(data, navigate)
        }
        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit} className='stripe-checkout-form'>
            {props.props.error ?
                    <div className="d-flex align-items-center border border-danger text-danger rounded-1 shadow-sm px-3 py-2 mb-3" role="alert">
                        <i className="bi bi-exclamation-triangle-fill fs-18"></i>
                        <div className='ms-2 lh-sm'>
                            {props.props.error}
                        </div>
                    </div> : ''
                }
            <div className='mb-3'>
                <label className='form-label'>Card Number</label>
                <CardNumberElement options={{ showIcon: true }} />
            </div>

            <div className='row gx-3'>
                <div className='col-6 mb-3'>
                    <label className='form-label'>Expiry Date</label>
                    <CardExpiryElement />
                </div>

                <div className='col-6 mb-3'>
                    <label className='form-label'>CVC</label>
                    <CardCvcElement options={{ CardCvcIcons: true }} className='cvc-icon' />
                </div>
            </div>

            <div className='btn-wrapper text-center pt-2'>
                <button className='w-100 btn btn-primary fs-18' type="submit" disabled={!stripe || !elements}>
                    {loading ? (
                        <div className="spinner-border text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : (
                        'Save'
                    )}
                </button>
            </div>
        </form>
    );
};

const Card = (props) => {
    const { state } = useLocation()
    let token = state.token;
    useEffect(() => {
    }, [state])

    return (
        <div className='card-details-page section-bg-image py-5' style={{ backgroundImage: `url(${require("../assets/bg-banner3.jpg")})` }}>
            <div className='container'>
                
                <div className='wrapper-width mx-auto' style={{ maxWidth: '550px' }}>
                    <h1 className="section-label fs-1 mb-3 pb-2" id="cardDetailsModalLabel">Save your card</h1>
                    <div className='form-wrapper bg-light border-5 border-top border-primary rounded shadow mx-auto px-3 px-sm-5 py-5'>
                        <Elements stripe={stripePromise}>
                            <CheckoutForm props={{ token: token, saveCard: props.saveCard,error:props.error }} />
                        </Elements>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveCard: (data, navigate) => dispatch(actions.saveCard(data, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(Card))
