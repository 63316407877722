import React, { useState } from "react";
import "./index.scss";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation} from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useEffect } from 'react';
// React Select NPM
import Select from "react-select";
import makeAnimated from "react-select/animated";
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'

const RebateBusinessLists = (props) => {
    const navigate = useNavigate();
    const animatedComponents = makeAnimated();
    const { getEngagedBusiness, engagedBusinessList } = props;
    
    const [selectedOptions, setSelectedOptions] = useState([]);
    const location = useLocation(); // Get location data
    const rebateData = location.state; // Extract passed state
    console.log(rebateData?.rebateData);
    console.log(rebateData?.remaining_claim);
    console.log(rebateData?.reciept);
    console.log(rebateData?.claim);
    console.log(rebateData?.amount);
    console.log(engagedBusinessList)
    // Define options for the select dropdown
    const options = engagedBusinessList?.length > 0 ?engagedBusinessList?.map(business => ({
        value: business._id,
        label: business.businessName
    })) : [];

    const handleChange = (selected) => {
        setSelectedOptions(selected);
    };
    const proofOfPurchase = () => {
        navigate("/upload-businesses-docs", {state: {
            selectedOptions: selectedOptions, 
            rebateData: rebateData?.rebateData,
            amount: rebateData?.amount,
        }})
    }
    useEffect(() => {
        getEngagedBusiness();
    }, [getEngagedBusiness]);
    console.log(engagedBusinessList)
    return (
        <div className="RebateBusinessList-page pt-3 pb-5">
            <div className="container">
                <Helmet>
                    <title>Rebate Business List</title>
                </Helmet>
                <div className="section-label fs-2 mb-3">Rebate Business List</div>

                <div className="info-wrapper">
                    <ul className="list ps-3">
                        <li>Your information will not be accepted, if you do not rate & comment on the businesses.</li>
                        <li>Your information will not be accepted, when you use the same business name twice when submitting your documents.</li>
                    </ul>
                </div>

                <div className="info-wrapper">
                    <div className="wrapper mb-3">
                        <div className="label-name fs-4 fw-bold">${rebateData?.amount} Rebate</div>
                        <div className="text-muted">List ({rebateData?.reciept}) business names.</div>
                    </div>
                    <Form>
                        <div className="select-wrapper">
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                options={options}
                                value={selectedOptions}
                                onChange={handleChange}
                                getOptionLabel={(e) => e.label}
                                noOptionsMessage={() => "No options available"}
                                placeholder={"Please select your businesses here..."}
                            />
                            <div className="mt-2">Selected <span className="text-primary fw-semibold">{selectedOptions.length}</span> option(s)</div>
                        </div>
                        {selectedOptions.length >= rebateData?.reciept ? 
                            <div className="btn-wrapper text-center mt-4 pt-lg-3">
                                <Button type="submit" variant="primary" onClick={() => proofOfPurchase()}>
                                    Next Page <i className="bi bi-arrow-right ms-1"></i>
                                </Button>
                            </div>
                            : 
                            <></>
                        }
                    </Form>
                </div>
            </div>
        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.rebate.loading,
        error: state.rebate.error,
        success: state.rebate.success,
        engagedBusinessList: state.rebate.engagedBusinessList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEngagedBusiness: () => dispatch(actions.getEngagedBusiness()),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(RebateBusinessLists))