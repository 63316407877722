import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startPlanAction = () => {
  return {
    type: actionTypes.START_PLAN_ACTION
  };
};

export const planActionFail = message => {
  return {
    type: actionTypes.PLAN_ACTION_FAIL,
    message
  };
};

export const fetchPlanSuccess = (planList, message = '') => {
  return {
    type: actionTypes.FETCH_PLAN_LIST_SUCCESS,
    listing: planList,
    message: message ? message : ''
  };
};

export const getSubscriptionCost = (cost, message = '') => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_COST,
    cost: cost,
    message: message ? message : ''
  };
};
export const fetchPlanTimePeriodSuccess = (planList, message = '') => {
  return {
    type: actionTypes.FETCH_PLAN_PERIOD_LIST_SUCCESS,
    listingTimePeriod: planList,
    message: message ? message : ''
  };
};

export const getPlanList = (adType) => {
  return dispatch => {
    dispatch(startPlanAction());
    const city = JSON.parse(localStorage.getItem('userInfo')).cityForSubscription
    console.log(city);
    axios
      .post("/couponranking/get-plan-list/"+ city, {
        subscription_type: adType
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchPlanSuccess(response.data.data));
          } else {
            dispatch(planActionFail(response.data.message));
          }
        } else {
          dispatch(planActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(planActionFail(err.message));
      });
  };
};


export const getSubscriptionTimePeriod = (adType) => {
  return dispatch => {
    dispatch(startPlanAction());
    axios
      .post("/couponranking/get-subscription-time-period", {
        subscription_type: adType
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchPlanTimePeriodSuccess(response.data.data));
          } else {
            dispatch(planActionFail(response.data.message));
          }
        } else {
          dispatch(planActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(planActionFail(err.message));
      });
  };
};

export const fetchPlanDetailOfUserSuccess = (details, message = '') => {
  return {
    type: actionTypes.FETCH_PLAN_DETAILS_SUCCESS,
    details: details,
    message: message ? message : ''
  };
};

export const getPlanDetailOfUser = () => {
  return dispatch => {
    dispatch(startPlanAction());
    let authtoken = "Bearer " + localStorage.getItem("token");
    axios
      .get("/couponranking/get-plan-details", {
        headers: { Authorization: authtoken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchPlanDetailOfUserSuccess(response.data.data));
          } else {
            dispatch(planActionFail(response.data.message));
          }
        } else {
          dispatch(planActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(planActionFail(err.message));
      });
  };
};

export const subscribePlanByUser = (data, adType, navigate) => {
  console.log(data)
  return dispatch => {
    dispatch(startPlanAction());
    let authtoken = "Bearer " + data.token;
    axios
      .post("/couponranking/subscribe-plan", {planId:data.planId}, {
        headers: { Authorization: authtoken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchPlanSuccess(response.data.data, response.data.message));
            // const load = () => { navigate('/add-business-details') }
            // adType = adType === "monthly"  ? "regular": "sponsered"
            const load = () => { navigate('/subscription-time-period', { state: {planId:data.planId, adType: adType }, replace: true }) } 
            setTimeout(load, 2000);
          } else {
            dispatch(planActionFail(response.data.message));
          }
        } else {
          dispatch(planActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(planActionFail(err.message));
      });
  };
};

export const subscribePlanTimePeriodByUser = (data, navigate) => {
  console.log(data)
  return dispatch => {
    dispatch(startPlanAction());
    let authtoken = "Bearer " + data.token;
    axios
      .post("/couponranking/subscribe-plan-update-time", {planId:data.planId, timePeriod: data.timePeriod, _id: data._id, salesPersonName:data.salesPersonName}, {
        headers: { Authorization: authtoken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchPlanSuccess(response.data.data, response.data.message));
            // const load = () => { navigate('/add-business-details') }
            const load = () => { navigate('/add-reference-details') }
            // adType = adType === "monthly"  ? "regular": "sponsered"
            setTimeout(load, 2000);
          } else {
            dispatch(planActionFail(response.data.message));
          }
        } else {
          dispatch(planActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(planActionFail(err.message));
      });
  };
};
export const subscriptionPlan = () => {
  return dispatch => {
    dispatch(startPlanAction());
    const city = JSON.parse(localStorage.getItem('userInfo')).cityForSubscription
    axios
      .get("/couponranking/subscription-fee/" + city)
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            console.log(response.data.data, "response.data.data");
            dispatch(getSubscriptionCost(response.data.data));
            // const load = () => { navigate('/add-business-details') }
            // adType = adType === "monthly"  ? "regular": "sponsered"
          } else {
            dispatch(planActionFail(response.data.message));
          }
        } else {
          dispatch(planActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(planActionFail(err.message));
      });
  };
};
